import React, { useEffect, useState } from "react"
import resilienceImage from "./../assets/images/takethetest.svg"
import journeyImage from "./../assets/images/chooseajourney.svg"
import habitsImage from "./../assets/images/buildnewhabits.svg"
import Layout from "../components/layout"
import TETorch from "./../assets/brands/te/te-torch.png"
import FAQs from "../components/faq"
import { Trans, withTranslation } from "react-i18next"

// This page is almost the same as the Welcome page - but with a different navigation and other small differences.

const featureTilesContent = [
  {
    image: resilienceImage,
    titleLanguageKey: "Onechange.featureTilesContent.resilience_title",
    descriptionLanguageKey:
      "Onechange.featureTilesContent.resilience_description",
  },
  {
    image: journeyImage,
    titleLanguageKey: "Onechange.featureTilesContent.journey_title",
    descriptionLanguageKey: "Onechange.featureTilesContent.journey_description",
  },
  {
    image: habitsImage,
    titleLanguageKey: "Onechange.featureTilesContent.habit_title",
    descriptionLanguageKey: "Onechange.featureTilesContent.habit_description",
  },
]
const IframeComponent = ({ src }) => {
  // Initialize state with no source
  const [iframeSrc, setIframeSrc] = useState("")

  // Update the source when the src prop changes
  useEffect(() => {
    if (src) {
      setTimeout(() => {
        setIframeSrc(src)
      }, 1000)
    }
  }, [src]) // Depend on src so it updates when src prop changes

  return (
    <iframe
      src={iframeSrc}
      frameBorder="0" // Optional: Remove iframe border
      allowFullScreen // Optional: Allow full screen
      style={{ width: "100%", height: 500 }} // Optional: Adjust style as needed
      title="Iframe Example" // Recommended: Provide a title for accessibility
    ></iframe>
  )
}
const OneChange = () => {
  return (
    <Layout>
      <section className="deck center-align">
        <header className="deck__viewport-height">
          <div className="row no-margin">
            <div className="flex-center">
              <img src={TETorch} alt="te-torch" className="torch-image" />
              <h1 className="big-title uppercase no-margin">
                <Trans i18nKey="Onechange.title" />
              </h1>
            </div>
            <div className="col l8 offset-l2 s12">
              <h2 className="text deck__title margin--top-10">
                <Trans i18nKey="Onechange.sub_text" />
              </h2>
            </div>
          </div>
          <div className="deck__video" style={{ width: "100%" }}>
            <div className="grid_col">
              <h3>
                <Trans i18nKey="intro.quick_intro" />
              </h3>
              {/* https://vimeo.com/926859868/8b7014f0e1?share=copy */}
              <IframeComponent src="https://player.vimeo.com/video/927066564?h=886e9ece0a&badge=0&autopause=0&player_id=0&app_id=58479" />
            </div>
            <div className="grid_col">
              <h3>
                <Trans i18nKey="intro.complete_challenge" />
              </h3>
              {/* https://vimeo.com/926859868/8b7014f0e1?share=copy */}
              <IframeComponent src="https://player.vimeo.com/video/926859868?h=8b7014f0e1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
            </div>
            <div className="grid_col">
              <h3>
                <Trans i18nKey="intro.get_most" />
              </h3>
              {/* https://vimeo.com/926032280/5b16f86516?share=copy */}
              <IframeComponent src="https://player.vimeo.com/video/927070818?h=202ab3a3e0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
            </div>
            <div className="grid_col">
              <h3>
                <Trans i18nKey="intro.read_scoreboard" />
              </h3>
              <IframeComponent src="https://player.vimeo.com/video/926908397?h=2b436aea3e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
            </div>
          </div>
          <FAQs />
        </header>
      </section>
    </Layout>
  )
}

export default withTranslation()(OneChange)
