import React, { useState } from 'react';
import { useRef } from 'react';
import { Trans, useTranslation } from "react-i18next";
import TETorch from "./../assets/brands/te/te-torch.png"

// Accordion Component
const Accordion = ({ question, answer, customAnswer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className="accordion-item">
      <button className="accordion-question" onClick={toggleAccordion}>
        {question}
        <span className={`accordion-icon ${isOpen ? 'open' : ''}`}>▼</span>
      </button>
      <div
        ref={contentRef}
        className="accordion-answer"
        style={{
          maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : '0',
          overflow: 'hidden',
          transition: 'max-height 0.3s ease'
        }}
      >
        {answer}
        {customAnswer && customAnswer}
      </div>
    </div>
  );
};

// FAQs Component
const FAQs = () => {
  const { t } = useTranslation();
  const renderCustomAnswerForQuestion13 = () => (
    <>
      <p>{t('faq.answer13')}</p>
      <ul>
        {t('faq.answer13_list', { returnObjects: true }).map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <p>{t('faq.answer13_footer')}</p>
    </>
  );
  const faqList1 = [
    { question: <Trans i18nKey="faq.question1"/> , answer: <Trans i18nKey="faq.answer1"/> },
    { question: <Trans i18nKey="faq.question2"/> , answer: <Trans i18nKey="faq.answer2"/> },
    { question: <Trans i18nKey="faq.question3"/> , answer: <Trans i18nKey="faq.answer3"/> },
    { question: <Trans i18nKey="faq.question4"/> , answer: <Trans i18nKey="faq.answer4"/> },
    { question: <Trans i18nKey="faq.question5"/> , answer: <Trans i18nKey="faq.answer5"/> },
    { question: <Trans i18nKey="faq.question6"/> , answer: <Trans i18nKey="faq.answer6"/> },
  ];
  const faqList2 = [
    { question: <Trans i18nKey="faq.question7"/> , answer: <Trans i18nKey="faq.answer7"/> },
    { question: <Trans i18nKey="faq.question8"/> , answer: <Trans i18nKey="faq.answer8"/> },
  ];
  const faqList3 = [
    { question: <Trans i18nKey="faq.question9"/> , answer: <Trans i18nKey="faq.answer9"/> },
    { question: <Trans i18nKey="faq.question10"/> , answer: <Trans i18nKey="faq.answer10"/> },
    { question: <Trans i18nKey="faq.question11"/> , answer: <Trans i18nKey="faq.answer11"/> },
    { question: <Trans i18nKey="faq.question12"/> , answer: <Trans i18nKey="faq.answer12"/> },
    { question: <Trans i18nKey="faq.question13"/> , customAnswer: renderCustomAnswerForQuestion13() },
    { question: <Trans i18nKey="faq.question14"/> , answer: <Trans i18nKey="faq.answer14"/> },
  ];
  const faqList4 = [
    { question: <Trans i18nKey="faq.question15"/> , answer: <Trans i18nKey="faq.answer15"/> }
  ];
  
  return (
    <div className="faq-section">
      <div className="flex-center">
        <img src={TETorch} alt="te-torch" className="torch-image"/>
        <h1 className="big-title uppercase no-margin">
          <Trans i18nKey="intro-faq.title"/>
        </h1>
      </div>
      <h2 className='faq_subtitle'><Trans i18nKey="faq.title1"/></h2>
      {faqList1.map((faq, index) => (
        <Accordion key={index} question={faq.question} answer={faq.answer} />
      ))}
      <h2 className='faq_subtitle'><Trans i18nKey="faq.title2"/></h2>
      {faqList2.map((faq, index) => (
        <Accordion key={index} question={faq.question} answer={faq.answer} />
      ))}
      <h2 className='faq_subtitle'><Trans i18nKey="faq.title3"/></h2>
      {faqList3.map((faq, index) => (
        <Accordion key={index} question={faq.question} answer={faq.answer} customAnswer={faq.customAnswer} />
      ))}
      <h2 className='faq_subtitle'><Trans i18nKey="faq.title4"/></h2>
      {faqList4.map((faq, index) => (
        <Accordion key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQs;